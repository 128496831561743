"use client";
// ^ this file needs the "use client" pragma

import { ApolloLink, HttpLink, split } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import {
  ApolloClient,
  ApolloNextAppProvider,
  InMemoryCache,
} from "@apollo/experimental-nextjs-app-support";
import { RestLink } from "apollo-link-rest";
import { createClient } from "graphql-ws";

// have a function to create a client for you
function makeClient() {
  const restLink = new RestLink({
  uri: process.env.REST_LINK || 'https://api.clone-business.com',
  });

  const graphqlLink = new HttpLink({
    uri: process.env.GRAPHQL_LINK || 'https://api.clone-business.com/graphql',
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.GRAPHQL_LINK || "https://api.clone-business.com/graphql",
    })
  );

  const authLink = new ApolloLink((operation, forward) => {
    const token =
      typeof window !== "undefined" ? localStorage.getItem("token") : "";
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    }));

    return forward(operation);
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    graphqlLink
  );

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([authLink, restLink, splitLink]),
    ssrMode: typeof window === "undefined",
  });
}

// you need to create a component to wrap your app in
export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  );
}
