"use client";

import AuthModal from "@/app/profile/AuthModal";
import DepositModal from "@/app/profile/DepositModal";
import { DefaultAvatar, Logo, WalletSmall } from "@/assets/images";
import { GET_ME } from "@/lib/graphql";
import { formatCurrency } from "@/lib/helper";
import { User } from "@/types";
import { useQuery } from "@apollo/client";
import { PlusCircle } from "@phosphor-icons/react";
import { motion } from "framer-motion";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";
import { MenuButton } from "./atom/MenuButton";
import LogoAnimation from "./LogoAnimation";
import { Button } from "./ui/button";
import { Skeleton } from "./ui/skeleton";

const Cart = dynamic(() => import("../app/shop-fb/Cart"), { ssr: false });
const Menu = dynamic(() => import("./Menu"), { ssr: false });

const Sidebar = () => {
  const pathName = usePathname();
  const [expanded, setExpanded] = React.useState(false);
  const [openLogin, setOpenLogin] = React.useState(false);
  const [openDeposit, setOpenDeposit] = React.useState(false);

  const {
    loading: authLoading,
    data,
    refetch,
  } = useQuery<{
    response: { data: { user: User } };
  }>(GET_ME, {
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(() => {
    if (window && typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      const tokenFromUrl = urlParams.get("token");

      if (tokenFromUrl) {
        localStorage.setItem("token", tokenFromUrl);
        window.location.href = "/";
      }
    }
  }, []);

  const user = data?.response.data.user;

  const UserWalletComponent = () => {
    if (user?.wallet)
      return (
        <div
          className="bg-neutural-canvas rounded-3xl p-2 cursor-pointer hover:outline-dashed outline-primary-content outline-[0.5px] conv__click_deposit"
          onClick={() => {
            setOpenDeposit(true);
          }}
        >
          <div className="flex items-center gap-2 relative conv__click_deposit">
            <div className="flex gap-1 items-center w-full conv__click_deposit">
              <div className="flex items-center gap-2 p-2 conv__click_deposit">
                <Image
                  src={WalletSmall}
                  alt="cart"
                  className="object-fit h-12 w-12 rounded-lg conv__click_deposit"
                />
              </div>
              <div className="conv__click_deposit conv__click_deposit">
                <div className="text-lg font-semibold text-neutural-strong conv__click_deposit">
                  {formatCurrency(user.wallet.balance || 0)}
                </div>
                <div className="text-sm font-normal text-neutural-light conv__click_deposit">
                  Số Dư Ví
                </div>
              </div>
            </div>

            <Button
              aria-label="Nạp tiền"
              className="bg-transparent border-none shadow-none text-2xl absolute -top-1 -right-1"
              variant="ghost"
            >
              <PlusCircle weight="light" />
            </Button>
          </div>
        </div>
      );

    return null;
  };

  const ProfileComponent = () => {
    if (user)
      return (
        <div className="flex gap-2 mt-4 p-2 items-center">
          <Image
            alt="avatar"
            className="object-fit h-12 w-12 rounded-full"
            height={48}
            width={48}
            src={user?.photoUrl || DefaultAvatar}
          />
          <div>
            <div className="text-normal text-neutural-strong overflow-hidden text-ellipsis whitespace-nowrap w-40">
              {user.email}
            </div>
            <Link href="/profile" rel="canonical">
              <Button
                variant="link"
                className="h-4 m-0 p-0 text-normal text-sm font-semibold cursor-pointer text-primary-content"
              >
                Xem chi tiết
              </Button>
            </Link>
          </div>
        </div>
      );

    return null;
  };

  const menuRef = React.useRef<HTMLDivElement>(null);
  // handle click any mouse event in menu, close the menu
  React.useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(e.target as Node) &&
        expanded
      ) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [expanded]);

  return (
    <div>
      <div
        className="md:hidden fixed top-0 z-[1] left-0 w-full justify-between bg-transparent backdrop-blur-[10px]"
        ref={menuRef}
      >
        <motion.div className="bg-white rounded-2xl shadow-2xl shadow-gray-50 mb-0 mx-2 mt-2 px-2 py-2">
          <div className="flex justify-between items-center">
            <Link href="/" aria-label="Trang Chủ" rel="canonical">
              <Image src={Logo} alt="logo" width={160} height={60} />
            </Link>
            <div className="flex items-center">
              <Cart isMobile />
              <Button
                variant="ghost"
                className="p-0"
                onClick={() => {
                  setExpanded(!expanded);
                }}
              >
                <MenuButton
                  aria-label={expanded ? "Close menu" : "Open menu"}
                  className="mx-2"
                  strokeWidth="2"
                  color="#083400"
                  isOpen={expanded}
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                />
              </Button>
            </div>
          </div>

          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: expanded ? "87vh" : 0,
              opacity: expanded ? 1 : 0,
            }}
            className="overflow-y-auto"
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <motion.div
              transition={{ duration: 0.2, ease: "easeInOut", delay: 0.1 }}
            >
              {authLoading ? (
                <>
                  <div className="bg-white rounded-3xl mx-4 p-2 cursor-pointer hover:outline-dashed outline-primary-content outline-[0.5px]">
                    <div className="flex items-center gap-2 relative">
                      <div className="flex gap-1 items-center w-full">
                        <div className="flex items-center gap-2 p-2">
                          <Skeleton className="h-10 w-10 rounded-full bg-neutural-canvas" />
                        </div>
                        <div>
                          <Skeleton
                            style={{ width: 80, height: 20 }}
                            className="bg-neutural-canvas"
                          />
                          <Skeleton
                            style={{ width: 60, height: 20 }}
                            className="mt-1 bg-neutural-canvas"
                          />
                        </div>
                      </div>

                      <Skeleton
                        style={{ width: 20, height: 20 }}
                        className="bg-neutural-canvas border-none shadow-none text-2xl absolute top-0 right-0 rounded-full"
                      />
                    </div>
                  </div>

                  <div className="flex gap-2 mx-4 mt-4 p-2 items-center">
                    <Skeleton
                      style={{ height: 40 }}
                      className="bg-neutural-canvas"
                    />
                    <div>
                      <Skeleton
                        style={{ width: 180, height: 20 }}
                        className="bg-neutural-canvas"
                      />
                      <Skeleton
                        style={{ width: 100, height: 20 }}
                        className="mt-1 bg-neutural-canvas"
                      />
                    </div>
                  </div>
                </>
              ) : user && user.id ? (
                <div className="mt-4 mx-2">
                  <UserWalletComponent />
                  <ProfileComponent />
                </div>
              ) : (
                <div className="md:m-0 m-2 conv__signin_or_signup">
                  <Button
                    variant="outline"
                    className="w-full conv__signin_or_signup"
                    onClick={() => {
                      setOpenLogin(true);
                    }}
                  >
                    Đăng Ký/Đăng Nhập
                  </Button>
                </div>
              )}

              <div className="overflow-y-auto flex-1 my-4">
                <Menu
                  currentPath={pathName}
                  containerClassName="px-2"
                  onMenuItemClick={() => {
                    setExpanded(false);
                  }}
                />
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>

      <div
        id="default-sidebar"
        className="fixed top-0 left-0 w-72 h-screen transition-transform -translate-x-full sm:translate-x-0 pb-4"
        aria-label="Sidebar"
      >
        <div className="m-2 flex flex-col h-full bg-white rounded-3xl pb-4">
          <div className="w-full px-8 py-4">
            <Link href="/" aria-label="Trang Chủ" rel="canonical">
              <LogoAnimation />
            </Link>
          </div>
          <Cart />
          <div className="overflow-y-auto flex-1 my-4">
            <Menu currentPath={pathName} containerClassName="px-4" />
          </div>

          {authLoading ? (
            <>
              <div className="bg-white rounded-3xl mx-4 p-2 cursor-pointer hover:outline-dashed outline-primary-content outline-[0.5px]">
                <div className="flex items-center gap-2 relative">
                  <div className="flex gap-1 items-center w-full">
                    <div className="flex items-center gap-2 p-2">
                      <Skeleton className="h-10 w-10 rounded-full bg-neutural-canvas" />
                    </div>
                    <div>
                      <Skeleton
                        style={{ width: 80, height: 20 }}
                        className="bg-neutural-canvas"
                      />
                      <Skeleton
                        style={{ width: 60, height: 20 }}
                        className="mt-1 bg-neutural-canvas"
                      />
                    </div>
                  </div>

                  <Skeleton
                    style={{ width: 20, height: 20 }}
                    className="bg-neutural-canvas border-none shadow-none text-2xl absolute top-0 right-0 rounded-full"
                  />
                </div>
              </div>

              <div className="flex gap-2 mx-4 mt-4 p-2 items-center">
                <Skeleton
                  style={{ height: 40 }}
                  className="bg-neutural-canvas"
                />
                <div>
                  <Skeleton
                    style={{ width: 180, height: 20 }}
                    className="bg-neutural-canvas"
                  />
                  <Skeleton
                    style={{ width: 100, height: 20 }}
                    className="mt-1 bg-neutural-canvas"
                  />
                </div>
              </div>
            </>
          ) : user && user.id ? (
            <div className="mx-4">
              <UserWalletComponent />
              <ProfileComponent />
            </div>
          ) : (
            <Button
              variant="outline"
              className="mx-4 mt-4 conv__signin_or_signup"
              onClick={() => {
                setOpenLogin(true);
              }}
            >
              Đăng Ký/Đăng Nhập
            </Button>
          )}
        </div>
      </div>
      <AuthModal open={openLogin} onClose={() => setOpenLogin(false)} />
      <DepositModal open={openDeposit} onClose={() => setOpenDeposit(false)} />
    </div>
  );
};

export default Sidebar;
